const salesOrganizationEmailMap = new Map([
	['1000', 'expeditie@swisssense.nl'],
	['3000', 'expeditie@swisssense.nl'],
	['4000', 'expedition@swisssense.de'],
	['4500', 'expedition@swisssense.de'],
	['8000', 'expeditie@luxebedden.nl'],
	['8400', 'expedition@luxebedden.de']
])

const salesOrganizationServiceEmailMap = new Map([
	['1000', 'contact@swisssense.nl'],
	['3000', 'contact@swisssense.nl'],
	['4000', 'contact@swisssense.de'],
	['4500', 'contact@swisssense.de'],
	['8000', 'contact@luxebedden.nl'],
	['8400', 'contact@luxebedden.de']
])

export const salesOrganizationEmailAddress = (salesOrganizationKey) =>
	salesOrganizationKey
		? salesOrganizationEmailMap.get(salesOrganizationKey)
		: null
export const salesOrganizationServiceEmailAddress = (salesOrganizationKey) =>
	salesOrganizationKey
		? salesOrganizationServiceEmailMap.get(salesOrganizationKey)
		: null
