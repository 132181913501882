import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextField, SvgIcon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { AngleLeftFal, AngleRightFal } from '@oliverit/react-fontawesome'
import { DatePicker } from '@oliverit/react-material-ui-pickers-moment'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styles = {
	root: {
		flex: 1
	},
	code: {
		paddingRight: 8,
		verticalAlign: 'baseline'
	},
	date: {
		verticalAlign: 'baseline'
	}
}

class Search extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		code: PropTypes.string,
		date: PropTypes.number,
		search: PropTypes.func.isRequired
	}

	state = {
		code: this.props.code,
		date: this.props.date
	}

	handleChangeCode = (event) => {
		const { date } = this.state
		const code = event.target.value

		this.setState({
			code
		})

		this.search(code, date)
	}

	handleChangeDate = (inputDate) => {
		const { code } = this.state

		const date = inputDate
			? moment
					.tz(inputDate, 'Europe/Amsterdam')
					.set({ hour: 12, minute: 12, seconds: 0 })
					.unix()
			: null

		this.setState({
			date
		})

		this.search(code, date)
	}

	search = (code, date) => {
		const { search } = this.props

		search({
			code,
			date
		})
	}

	render() {
		const { classes } = this.props
		const { code, date } = this.state

		let dateMoment = null
		if (date) {
			dateMoment = moment.unix(date).tz('Europe/Amsterdam')
		}

		return (
			<div className={classes.root}>
				<TextField
					value={code}
					placeholder={i18n.t('app:postcodeadmin.Postcodes.Search.code')}
					onChange={this.handleChangeCode}
					className={classes.code}
				/>
				<DatePicker
					value={dateMoment}
					onChange={this.handleChangeDate}
					autoOk={true}
					clearable={true}
					label={i18n.t('app:postcodeadmin.Postcodes.Search.date')}
					cancelLabel={i18n.t('app:datepicker.cancel')}
					clearLabel={i18n.t('app:datepicker.clear')}
					format="D MMMM Y"
					okLabel={i18n.t('app:datepicker.ok')}
					todayLabel={i18n.t('app:datepicker.today')}
					leftArrowIcon={
						<SvgIcon>
							<AngleLeftFal />
						</SvgIcon>
					}
					rightArrowIcon={
						<SvgIcon>
							<AngleRightFal />
						</SvgIcon>
					}
					margin="normal"
					className={classes.date}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(Search)
