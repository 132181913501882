import {
	PLANNING_TYPE_SERVICE,
	PLANNING_TYPE_SERVICE_DUITSLAND
} from '../constants'

const alohaExpeditieMap = new Map([
	['1000', '32113'],
	['3000', '31520'],
	['4000', '32116'],
	['4500', '32117'],
	['8000', '31510'],
	['8400', '32118']
])

const alohaCuCaMap = new Map([
	['1000', '31424'],
	['3000', '31425'],
	['4000', '31765'],
	['4500', '31533'],
	['8000', '32114'],
	['8400', '31512']
])

const alohaUrl = (planningTypeKey, salesOrganizationKey, phoneNumber) => {
	const queue =
		planningTypeKey === PLANNING_TYPE_SERVICE ||
		planningTypeKey === PLANNING_TYPE_SERVICE_DUITSLAND
			? alohaCuCaMap.get(salesOrganizationKey)
			: alohaExpeditieMap.get(salesOrganizationKey)
	if (queue) {
		return `https://desktop.eu5.quandago.app/api/v2/telephony/dial/${phoneNumber}/${queue}.html`
	}
	return null
}

export default alohaUrl
