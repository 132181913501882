import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Chip,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	MenuItem,
	Select,
	Switch,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AngleLeftFal,
	AngleRightFal,
	CalendarEditFal,
	ExclamationCircleFal,
	RedoFal
} from '@oliverit/react-fontawesome'
import { DatePicker } from '@oliverit/react-material-ui-pickers-moment'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import { getSuggestedDate } from '../../../../utils/appointment'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	dateSelection: {
		marginBottom: 16
	},
	dateSelectionPicker: {
		flex: 1
	},
	dateSelectionRow: {
		display: 'flex',
		alignItems: 'center',
		flex: 1
	},
	dateSelectionLoading: {
		marginLeft: 8
	},
	dateSelectionEmpty: {
		flex: 1
	},
	dateSelectionDefault: {
		flex: 1
	},
	dateSelectioWarning: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		marginTop: 8
	},
	dateSelectioWarningIcon: {
		marginRight: 8
	},
	timeSelection: {
		marginTop: 8,
		marginBottom: 8
	},
	confirmButton: {
		marginTop: 16
	},
	ratingChip: {
		marginLeft: 8
	}
}

class DeliveryDate extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		dateProposal: PropTypes.object,
		dateProposalLoading: PropTypes.bool,
		order: PropTypes.object.isRequired,
		getProposal: PropTypes.func.isRequired,
		setDeliveryDate: PropTypes.func.isRequired,
		timeSlots: PropTypes.array.isRequired,
		tourDate: PropTypes.string,
		unavailableProposalMomentsForOrder: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)

		const suggestedDate = getSuggestedDate(props.order)

		this.state = {
			selectedDate: suggestedDate,
			selectedTimeFromHour: 7,
			selectedTimeFromMinute: 0,
			selectedTimeTillHour: 17,
			selectedTimeTillMinute: 0,
			showDatePicker: false,
			showTime: false, // Set to true by 2 different datepicking flows. Shows time dropdowns.
			showProposal: false,
			overrideProposal: false, // User chooses Other Date in proposal dropdown. User will be offered a datepicker.
			datePickerUsed: false,
			selectedTimeSlot: { from: '' }
		}
	}

	componentDidUpdate(prevProps) {
		const { dateProposal, dateProposalLoading } = this.props
		const areDateProposalsReloaded = Boolean(
			prevProps.dateProposalLoading && !dateProposalLoading
		)
		if (areDateProposalsReloaded) {
			if (
				dateProposal &&
				dateProposal.proposals &&
				dateProposal.proposals.length > 0
			) {
				const dateProposalMoments = dateProposal.proposals.map((proposal) =>
					moment.tz(proposal.date, 'Europe/Amsterdam')
				)
				this.setState({
					earliestDateProposal: moment
						.unix(dateProposalMoments[0].unix())
						.tz('Europe/Amsterdam')
				})
			} else {
				this.setState({
					earliestDateProposal: null
				})
			}
		}
	}

	handleSelectProposedDate =
		(earliestDateProposal, appointmentTimeFrom, appointmentTimeTill) =>
		(event) => {
			const { timeSlots } = this.props
			if (event.target.value === -1) {
				// This will trigger the datepicker
				const selectedDate = timeSlots[0]
					? moment(timeSlots[0].from).tz('Europe/Amsterdam')
					: moment.unix(0)
				// Automatically select time slot if there is only one
				const availableTimeSlotsForSelectedDate = timeSlots.filter(
					(availableDate) =>
						moment(availableDate.from).isSame(selectedDate, 'day')
				)
				let stateUpdate = {}
				if (availableTimeSlotsForSelectedDate.length === 1) {
					const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
					stateUpdate = {
						selectedTimeSlot: {
							from: firstTimeSlot.from,
							till: firstTimeSlot.till
						},
						selectedTimeFromHour: moment(firstTimeSlot.from).hour(),
						selectedTimeFromMinute: moment(firstTimeSlot.from).minute(),
						selectedTimeTillHour: moment(firstTimeSlot.till).hour(),
						selectedTimeTillMinute: moment(firstTimeSlot.from).minute()
					}
				}

				this.setState({
					selectedDate,
					datePickerUsed: true,
					overrideProposal: true,
					showTime: false,
					...stateUpdate
				})
			} else if (
				appointmentTimeFrom &&
				appointmentTimeTill &&
				event.target.value === earliestDateProposal
			) {
				// The selected proposal is the earliest proposed date and has a time block, so preset the selected times
				this.setState({
					earliestDateProposal: moment
						.unix(earliestDateProposal)
						.tz('Europe/Amsterdam'),
					selectedDate: moment.unix(event.target.value).tz('Europe/Amsterdam'),
					selectedTimeFromHour: moment(appointmentTimeFrom, 'HH:mm').hour(),
					selectedTimeFromMinute: moment(appointmentTimeFrom, 'HH:mm').minute(),
					selectedTimeTillHour: moment(appointmentTimeTill, 'HH:mm').hour(),
					selectedTimeTillMinute: moment(appointmentTimeTill, 'HH:mm').minute()
				})
			} else {
				// The selected proposal does not have a time block, so preset the selected times to default (7:00-17:00)
				this.setState({
					earliestDateProposal: moment
						.unix(earliestDateProposal)
						.tz('Europe/Amsterdam'),
					selectedDate: moment.unix(event.target.value).tz('Europe/Amsterdam'),
					selectedTimeFromHour: 7,
					selectedTimeFromMinute: 0,
					selectedTimeTillHour: 17,
					selectedTimeTillMinute: 0
				})
			}
		}

	handleChangeSelectedDate = (selectedDate) => {
		const { timeSlots } = this.props
		let selectedTimeFromHour = 7
		let selectedTimeFromMinute = 0
		let selectedTimeTillHour = 17
		let selectedTimeTillMinute = 0
		let selectedTimeSlot = { from: '' }
		// Automatically select time slot if there is only one
		const availableTimeSlotsForSelectedDate = timeSlots.filter(
			(availableDate) => moment(availableDate.from).isSame(selectedDate, 'day')
		)
		if (availableTimeSlotsForSelectedDate.length === 1) {
			const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
			selectedTimeSlot = {
				from: firstTimeSlot.from,
				till: firstTimeSlot.till
			}
			selectedTimeFromHour = moment(firstTimeSlot.from).hour()
			selectedTimeFromMinute = moment(firstTimeSlot.from).minute()
			selectedTimeTillHour = moment(firstTimeSlot.till).hour()
			selectedTimeTillMinute = moment(firstTimeSlot.from).minute()
		}

		this.setState({
			selectedDate,
			datePickerUsed: true,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			selectedTimeSlot
		})
	}

	handleChangeSelectedTimeFromHour = (event) => {
		const { selectedTimeFromMinute, selectedTimeTillHour } = this.state
		const selectedTimeFromHour = event.target.value
		if (selectedTimeFromHour === selectedTimeTillHour) {
			// Shift the till time by 30 minutes if the from time became the same
			if (selectedTimeFromMinute === 0) {
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillMinute: 30
				})
			} else {
				let nextSelectedTimeTillHour = selectedTimeTillHour + 1
				if (nextSelectedTimeTillHour > 17) {
					nextSelectedTimeTillHour = 17
				}
				const nextSelectedTimeTillMinute = selectedTimeTillHour === 17 ? 30 : 0
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillHour: nextSelectedTimeTillHour,
					selectedTimeTillMinute: nextSelectedTimeTillMinute
				})
			}
		} else if (selectedTimeFromHour > selectedTimeTillHour) {
			// Shift the till time by 30 minutes and adjust the till hour if the from time became later than the till time
			if (selectedTimeFromMinute === 0) {
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillHour: selectedTimeFromHour,
					selectedTimeTillMinute: 30
				})
			} else {
				let nextSelectedTimeTillHour = selectedTimeTillHour + 1
				if (nextSelectedTimeTillHour > 17) {
					nextSelectedTimeTillHour = 17
				}
				const nextSelectedTimeTillMinute = selectedTimeTillHour === 17 ? 30 : 0
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillHour: nextSelectedTimeTillHour,
					selectedTimeTillMinute: nextSelectedTimeTillMinute
				})
			}
		} else {
			this.setState({
				selectedTimeFromHour
			})
		}
	}

	handleChangeSelectedTimeFromMinute = (event) => {
		const { selectedTimeFromHour, selectedTimeTillHour } = this.state
		const selectedTimeFromMinute = event.target.value
		if (selectedTimeFromHour === selectedTimeTillHour) {
			// Shift the till time by 30 minutes if the from time became the same
			if (selectedTimeFromMinute === 0) {
				this.setState({
					selectedTimeFromMinute,
					selectedTimeTillMinute: 30
				})
			} else {
				let nextSelectedTimeTillHour = selectedTimeTillHour + 1
				if (nextSelectedTimeTillHour > 17) {
					nextSelectedTimeTillHour = 17
				}
				const nextSelectedTimeTillMinute = selectedTimeTillHour === 17 ? 30 : 0
				this.setState({
					selectedTimeFromMinute,
					selectedTimeTillHour: nextSelectedTimeTillHour,
					selectedTimeTillMinute: nextSelectedTimeTillMinute
				})
			}
		} else {
			this.setState({
				selectedTimeFromMinute
			})
		}
	}

	handleChangeSelectedTimeTillHour = (event) => {
		const { selectedTimeFromHour } = this.state
		const selectedTimeTillHour = event.target.value
		if (selectedTimeFromHour === selectedTimeTillHour) {
			// Shift the till time by 30 minutes if the from time became the same
			this.setState({
				selectedTimeTillHour,
				selectedTimeTillMinute: 30
			})
		} else {
			this.setState({
				selectedTimeTillHour
			})
		}
	}

	handleChangeSelectedTimeTillMinute = (event) => {
		const selectedTimeTillMinute = event.target.value
		this.setState({
			selectedTimeTillMinute
		})
	}

	handleToggleShowTime = () => {
		const { timeSlots } = this.props
		const { selectedDate, showTime } = this.state
		let selectedTimeFromHour = 7
		let selectedTimeFromMinute = 0
		let selectedTimeTillHour = 17
		let selectedTimeTillMinute = 0
		let selectedTimeSlot = { from: '' }
		// Automatically select time slot if there is only one
		const availableTimeSlotsForSelectedDate = timeSlots.filter(
			(availableDate) => moment(availableDate.from).isSame(selectedDate, 'day')
		)
		if (availableTimeSlotsForSelectedDate.length === 1) {
			const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
			selectedTimeSlot = {
				from: firstTimeSlot.from,
				till: firstTimeSlot.till
			}
			selectedTimeFromHour = moment(firstTimeSlot.from).hour()
			selectedTimeFromMinute = moment(firstTimeSlot.from).minute()
			selectedTimeTillHour = moment(firstTimeSlot.till).hour()
			selectedTimeTillMinute = moment(firstTimeSlot.from).minute()
		}

		this.setState({
			showTime: !showTime,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			selectedTimeSlot
		})
	}

	handleConfirm = () => {
		const { setDeliveryDate } = this.props
		const {
			earliestDateProposal,
			datePickerUsed,
			selectedDate,
			selectedTimeSlot,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			showTime
		} = this.state

		let formattedEarliestDateProposal
		if (earliestDateProposal) {
			formattedEarliestDateProposal = earliestDateProposal.format()
		}

		const isDatepickerDate = datePickerUsed

		if (selectedDate) {
			if (showTime || (selectedTimeSlot && selectedTimeSlot.from)) {
				// Time selection was made
				const from = selectedDate
					.set({
						hour: selectedTimeFromHour,
						minute: selectedTimeFromMinute,
						second: 0,
						millisecond: 0
					})
					.format()
				const till = selectedDate
					.set({
						hour: selectedTimeTillHour,
						minute: selectedTimeTillMinute,
						second: 0,
						millisecond: 0
					})
					.format()
				setDeliveryDate(
					{ from, till },
					formattedEarliestDateProposal,
					isDatepickerDate
				)
			} else {
				// No time selection
				const from = selectedDate
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.format()
				const till = selectedDate
					.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
					.format()
				setDeliveryDate(
					{ from, till },
					formattedEarliestDateProposal,
					isDatepickerDate
				)
			}
		}
	}

	handleShowProposal = () => {
		const { dateProposal, timeSlots } = this.props
		if (
			dateProposal &&
			dateProposal.proposals &&
			dateProposal.proposals.length > 0
		) {
			this.setState({
				selectedDate: moment.unix(0),
				showProposal: true
			})
		} else {
			// No proposal available, switch to datepicker
			const selectedDate = timeSlots[0]
				? moment(timeSlots[0].from).tz('Europe/Amsterdam')
				: moment.unix(0)
			// Automatically select time slot if there is only one
			const availableTimeSlotsForSelectedDate = timeSlots.filter(
				(availableDate) =>
					moment(availableDate.from).isSame(selectedDate, 'day')
			)
			let stateUpdate = {}
			if (availableTimeSlotsForSelectedDate.length === 1) {
				const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
				stateUpdate = {
					selectedTimeSlot: {
						from: firstTimeSlot.from,
						till: firstTimeSlot.till
					},
					selectedTimeFromHour: moment(firstTimeSlot.from).hour(),
					selectedTimeFromMinute: moment(firstTimeSlot.from).minute(),
					selectedTimeTillHour: moment(firstTimeSlot.till).hour(),
					selectedTimeTillMinute: moment(firstTimeSlot.from).minute()
				}
			}

			this.setState({
				selectedDate,
				datePickerUsed: true,
				overrideProposal: true,
				showProposal: true,
				showTime: false,
				...stateUpdate
			})
		}
	}

	handleShowDatePicker = () => {
		this.setState({
			showDatePicker: true
		})
	}

	handleCloseDatePicker = () => {
		this.setState({
			showDatePicker: false
		})
	}

	handleReset = () => {
		const { getProposal, order } = this.props
		getProposal()
		const suggestedDate = getSuggestedDate(order)
		this.setState({
			selectedDate: suggestedDate,
			selectedTimeFromHour: 7,
			selectedTimeFromMinute: 0,
			selectedTimeTillHour: 17,
			selectedTimeTillMinute: 0,
			showDatePicker: false,
			showTime: false, // Set to true by 2 different datepicking flows. Shows time dropdowns.
			showProposal: false,
			overrideProposal: false, // User chooses Other Date in proposal dropdown. User will be offered a datepicker.
			datePickerUsed: false,
			selectedTimeSlot: { from: '' }
		})
	}

	shouldDisableDate = (date) => {
		const { timeSlots } = this.props
		return !timeSlots.some((availableDate) =>
			moment(availableDate.from).isSame(date, 'day')
		)
	}

	handleChangeSelectedTimeSlot = (event) => {
		const { timeSlots } = this.props
		const selectedTimeSlotFrom = event.target.value
		const selectedTimeSlotFound = timeSlots.find(
			(timeSlot) => timeSlot.from === selectedTimeSlotFrom
		)

		this.setState({
			selectedTimeSlot: selectedTimeSlotFound,
			selectedTimeFromHour: moment(selectedTimeSlotFound.from).hour(),
			selectedTimeFromMinute: moment(selectedTimeSlotFound.from).minute(),
			selectedTimeTillHour: moment(selectedTimeSlotFound.till).hour(),
			selectedTimeTillMinute: moment(selectedTimeSlotFound.from).minute()
		})
	}

	getTimeInput = () => {
		const {
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			showTime
		} = this.state
		const { classes } = this.props

		let timeInput
		if (showTime) {
			let minimumSelectedTimeTillHour =
				selectedTimeFromMinute === 0
					? selectedTimeFromHour
					: selectedTimeFromHour + 1
			const minimumSelectedTimeTillMinute =
				selectedTimeFromHour === selectedTimeTillHour ? 30 : 0
			if (minimumSelectedTimeTillHour > 17) {
				minimumSelectedTimeTillHour = 17
			}
			const selectedTimeFromHourItems = [
				7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
			].map((hour) => (
				<MenuItem key={`from-hour-${hour}`} value={hour}>
					{hour}
				</MenuItem>
			))
			const selectedTimeTillHourItems = [
				7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
			]
				.filter((hour) => hour >= minimumSelectedTimeTillHour)
				.map((hour) => (
					<MenuItem key={`till-hour-${hour}`} value={hour}>
						{hour}
					</MenuItem>
				))
			const selectedTimeFromMinuteItems = [
				{ val: 0, desc: '00' },
				{ val: 30, desc: '30' }
			].map((minute) => (
				<MenuItem key={`from-minute-${minute.val}`} value={minute.val}>
					{minute.desc}
				</MenuItem>
			))
			const selectedTimeTillMinuteItems = [
				{ val: 0, desc: '00' },
				{ val: 30, desc: '30' }
			]
				.filter((minute) => minute.val >= minimumSelectedTimeTillMinute)
				.map((minute) => (
					<MenuItem key={`till-minute-${minute.val}`} value={minute.val}>
						{minute.desc}
					</MenuItem>
				))

			timeInput = (
				<Grid container className={classes.timeSelection}>
					<Grid item xs={6}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="caption" color="primary">
									{i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.from'
									)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Select
									value={selectedTimeFromHour}
									onChange={this.handleChangeSelectedTimeFromHour}
								>
									{selectedTimeFromHourItems}
								</Select>
								<Select
									value={selectedTimeFromMinute}
									onChange={this.handleChangeSelectedTimeFromMinute}
								>
									{selectedTimeFromMinuteItems}
								</Select>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="caption" color="primary">
									{i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.till'
									)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Select
									value={selectedTimeTillHour}
									onChange={this.handleChangeSelectedTimeTillHour}
								>
									{selectedTimeTillHourItems}
								</Select>
								<Select
									value={selectedTimeTillMinute}
									onChange={this.handleChangeSelectedTimeTillMinute}
								>
									{selectedTimeTillMinuteItems}
								</Select>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)
		}

		return timeInput
	}

	render() {
		const { classes, dateProposal, dateProposalLoading, timeSlots, tourDate } =
			this.props
		const {
			datePickerUsed,
			overrideProposal,
			selectedDate,
			showDatePicker,
			showProposal,
			showTime,
			selectedTimeSlot
		} = this.state

		const now = moment.tz('Europe/Amsterdam')

		let disableConfirmButton = false
		if (!now.isSameOrBefore(selectedDate, 'day')) {
			disableConfirmButton = true
		}
		if (datePickerUsed && !showTime && !selectedTimeSlot.from) {
			disableConfirmButton = true
		}
		if (!tourDate) {
			// tours without a tour date have not been planned yet by the planning run and therefore must be planned manually
			if (datePickerUsed && !showTime && !selectedTimeSlot.from) {
				// if the date picker is used, then a custom time or time slot needs to be selected
				disableConfirmButton = true
			} else if (showProposal && !selectedDate) {
				// if the date proposal is used, then a date needs to be selected
				disableConfirmButton = true
			} else if (!datePickerUsed && !showProposal) {
				// date picker or proposal date must be used if the order does not have a planning date yet
				disableConfirmButton = true
			}
		}

		const datePicker = (
			<DatePicker
				open={showDatePicker}
				value={selectedDate}
				onChange={this.handleChangeSelectedDate}
				onClose={this.handleCloseDatePicker}
				autoOk={true}
				clearable={false}
				label={i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.label')}
				cancelLabel={i18n.t('app:datepicker.cancel')}
				clearLabel={i18n.t('app:datepicker.clear')}
				format="D MMMM Y"
				minDate={moment.tz(
					{ hour: 0, minute: 0, seconds: 0, milliseconds: 0 },
					'Europe/Amsterdam'
				)}
				minDateMessage={i18n.t(
					'app:appointmentscheduler.Order.Edit.DeliveryDate.minDateErrorText'
				)}
				shouldDisableDate={this.shouldDisableDate}
				okLabel={i18n.t('app:datepicker.ok')}
				todayLabel={i18n.t('app:datepicker.today')}
				leftArrowIcon={
					<SvgIcon>
						<AngleLeftFal />
					</SvgIcon>
				}
				rightArrowIcon={
					<SvgIcon>
						<AngleRightFal />
					</SvgIcon>
				}
				margin="normal"
				style={{ display: 'none' }}
			/>
		)

		let spinnerDateProposalLoading
		if (dateProposalLoading) {
			spinnerDateProposalLoading = (
				<div className={classes.dateSelectionRow}>
					<CircularProgress size={20} />
					<Typography
						variant="subtitle1"
						className={classes.dateSelectionLoading}
					>
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.loading')}
					</Typography>
				</div>
			)
		}

		let dateSelection
		let datesMenuItems
		if (!showProposal) {
			// Show planned delivery date
			dateSelection = (
				<div className={classes.dateSelectionRow}>
					<div className={classes.dateSelectionDefault}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.label')}
						</Typography>
						<Typography variant="subtitle1">
							{selectedDate.format('dddd D MMMM Y')}
						</Typography>
					</div>
					<Button
						variant="outlined"
						color="primary"
						onClick={this.handleShowProposal}
					>
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.change')}
					</Button>
				</div>
			)
		} else if (
			dateProposal &&
			dateProposal.proposals &&
			dateProposal.proposals.length > 0 &&
			!overrideProposal
		) {
			// Show alternative dates
			const firstDateProposal = moment
				.tz(dateProposal.proposals[0].date, 'Europe/Amsterdam')
				.unix()
			const firstDateProposalAppointmentTimeFrom =
				dateProposal.proposals[0].appointmentTimeFrom
			const firstDateProposalAppointmentTimeTill =
				dateProposal.proposals[0].appointmentTimeTill
			datesMenuItems = dateProposal.proposals.map((proposal, index) => {
				const dateProposalMoment = moment.tz(proposal.date, 'Europe/Amsterdam')
				const chip = proposal.isSuggested ? (
					<Chip
						className={classes.ratingChip}
						label={i18n.t('app:appointmentscheduler.Order.tourDate')}
						size="small"
						color="default"
						variant="outlined"
					/>
				) : (
					<Chip
						className={classes.ratingChip}
						label={`${proposal.rating}%`}
						size="small"
						color="primary"
						variant="outlined"
					/>
				)
				return (
					<MenuItem key={`date-${index}`} value={dateProposalMoment.unix()}>
						<Grid container alignItems="center">
							<Grid item>
								{dateProposalMoment.format(
									`dddd D MMMM Y [(${i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.week'
									)}] w[)]`
								)}
							</Grid>
							<Grid item>{chip}</Grid>
						</Grid>
					</MenuItem>
				)
			})
			datesMenuItems.unshift(
				<MenuItem key="date-none-selected" value={0}>
					{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.select')}
				</MenuItem>
			)
			datesMenuItems.push(
				<MenuItem key="date-override" value={-1}>
					{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.other')}
				</MenuItem>
			)
			dateSelection = (
				<FormControl className={classes.dateSelectionPicker}>
					<InputLabel htmlFor="date-selection">
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.label')}
					</InputLabel>
					<Select
						value={selectedDate.unix()}
						onChange={this.handleSelectProposedDate(
							firstDateProposal,
							firstDateProposalAppointmentTimeFrom,
							firstDateProposalAppointmentTimeTill
						)}
						inputProps={{
							name: 'date',
							id: 'date-selection'
						}}
					>
						{datesMenuItems}
					</Select>
				</FormControl>
			)
		} else if (timeSlots && timeSlots.length > 0 && overrideProposal) {
			// Show selection for timeslots
			const availableTimeSlotsForSelectedDate = timeSlots.filter(
				(availableDate) =>
					moment(availableDate.from).isSame(selectedDate, 'day')
			)
			const formattedTimeSlots = availableTimeSlotsForSelectedDate.map(
				(timeSlot) => ({
					from: timeSlot.from,
					fromFormatted: moment(timeSlot.from).format('HH:mm'),
					till: timeSlot.till,
					tillFormatted: moment(timeSlot.till).format('HH:mm')
				})
			)
			const timeSlotItems = formattedTimeSlots.map((timeSlot) => (
				<MenuItem
					key={timeSlot.from}
					value={timeSlot.from}
				>{`${timeSlot.fromFormatted}-${timeSlot.tillFormatted}`}</MenuItem>
			))
			timeSlotItems.unshift(
				<MenuItem key="placeholder" value="placeholder" disabled>
					{i18n.t(
						'app:appointmentscheduler.Order.Edit.DeliveryDate.timeSlotPlaceholder'
					)}
				</MenuItem>
			)
			let timeSlotSelection
			if (!showTime) {
				timeSlotSelection = (
					<div className={classes.dateSelectionRow}>
						<Grid container type="column" className={classes.timeSelection}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="caption" color="primary">
										{i18n.t(
											'app:appointmentscheduler.Order.Edit.DeliveryDate.timeSlot'
										)}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Select
										value={selectedTimeSlot.from || 'placeholder'}
										onChange={this.handleChangeSelectedTimeSlot}
										fullWidth
									>
										{timeSlotItems}
									</Select>
								</Grid>
							</Grid>
						</Grid>
					</div>
				)
			}
			dateSelection = (
				<>
					<div className={classes.dateSelectionRow}>
						<div className={classes.dateSelectionDefault}>
							<Typography variant="caption" color="primary">
								{i18n.t(
									'app:appointmentscheduler.Order.Edit.DeliveryDate.label'
								)}
							</Typography>
							<Typography variant="subtitle1">
								{selectedDate.format('dddd D MMMM Y')}
							</Typography>
						</div>
						<IconButton color="primary" onClick={this.handleShowDatePicker}>
							<SvgIcon>
								<CalendarEditFal />
							</SvgIcon>
						</IconButton>
						<IconButton color="primary" onClick={this.handleReset}>
							<SvgIcon>
								<RedoFal />
							</SvgIcon>
						</IconButton>
						{datePicker}
					</div>
					{timeSlotSelection}
				</>
			)
		} else {
			// Show no available proposal date
			dateSelection = (
				<div className={classes.dateSelectionRow}>
					<div className={classes.dateSelectionDefault}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.label')}
						</Typography>
						<div className={classes.dateSelectioWarning}>
							<SvgIcon
								color="secondary"
								className={classes.dateSelectioWarningIcon}
							>
								<ExclamationCircleFal />
							</SvgIcon>
							<div>
								<Typography
									color="secondary"
									variant="caption"
									className={classes.dateSelectionEmpty}
								>
									{i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.empty1'
									)}
								</Typography>
								<Typography
									color="secondary"
									variant="caption"
									className={classes.dateSelectionEmpty}
								>
									{i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.empty2'
									)}
								</Typography>
							</div>
						</div>
					</div>
					<IconButton color="primary" onClick={this.handleReset}>
						<SvgIcon>
							<RedoFal />
						</SvgIcon>
					</IconButton>
					{datePicker}
				</div>
			)
		}

		const specifyOrderTime = (
			<List disablePadding>
				<ListItem button disableGutters onClick={this.handleToggleShowTime}>
					<ListItemText
						primary={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryDate.showTime'
						)}
						primaryTypographyProps={{ variant: 'body2' }}
					/>
					<ListItemSecondaryAction>
						<Switch
							checked={showTime}
							color="primary"
							onChange={this.handleToggleShowTime}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		)

		const timeInput = this.getTimeInput()

		return (
			<div className={classes.root}>
				<div className={classes.dateSelection}>
					{dateSelection}
					{spinnerDateProposalLoading}
				</div>
				{specifyOrderTime}
				{timeInput}
				<Button
					color="primary"
					variant="contained"
					disabled={disableConfirmButton}
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DeliveryDate)
