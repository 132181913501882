import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const styleSheet = {
	root: {
		backgroundColor: grey[200],
		borderRadius: 12,
		marginBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 16,
		position: 'relative',
		maxWidth: '75%',
		marginRight: 'auto',
		'&:before': {
			position: 'absolute',
			top: 16,
			left: -8,
			content: "' '",
			display: 'inline-block',
			width: 0,
			height: 0,
			marginLeft: 2,
			verticalAlign: 'middle',
			borderTop: '6px solid transparent',
			borderBottom: '6px solid transparent',
			borderRight: `6px solid ${grey[200]}`
		}
	}
}

class ChatBubbleAnswer extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired
	}

	render() {
		const { classes, children } = this.props

		return <div className={classes.root}>{children}</div>
	}
}

export default withStyles(styleSheet)(ChatBubbleAnswer)
