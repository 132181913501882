import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import ChatBubbleAnswer from './ChatBubbleAnswer'
import { i18nCallScript } from '../../../../utils/callscript'
import { ORDER_TYPE_SERVICE } from '../../../../utils/constants'

const styleSheet = {}

class Remarks extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired
	}

	render() {
		const { appointment, order, lng } = this.props

		const isServiceOrder = order.orderType.key === ORDER_TYPE_SERVICE

		let line1
		switch (lng) {
			default: {
				if (isServiceOrder) {
					line1 = (
						<ChatBubbleText>
							{i18nCallScript.t(
								'callscript:Remarks.Question.line1ServiceOrder'
							)}
						</ChatBubbleText>
					)
				} else {
					line1 = (
						<ChatBubbleText>
							{i18nCallScript.t('callscript:Remarks.Question.line1')}
						</ChatBubbleText>
					)
				}
			}
		}

		let answer
		if (appointment.bijzonderheden !== null) {
			let answerLine
			switch (lng) {
				default: {
					if (appointment.bijzonderheden) {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:Remarks.Answer.line1Yes"
								>
									<strong>Ja</strong>, er zijn bijzonderheden.
								</Trans>
							</ChatBubbleText>
						)
					} else {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:Remarks.Answer.line1No"
								>
									<strong>Nee</strong>, er zijn geen bijzonderheden.
								</Trans>
							</ChatBubbleText>
						)
					}
				}
			}
			answer = <ChatBubbleAnswer>{answerLine}</ChatBubbleAnswer>
		}

		return (
			<>
				{answer}
				<ChatBubbleQuestion>{line1}</ChatBubbleQuestion>
			</>
		)
	}
}

export default withStyles(styleSheet)(Remarks)
