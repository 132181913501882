import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { i18nCallScript } from '../../../../utils/callscript'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import ChatBubbleAnswer from './ChatBubbleAnswer'
import { formatCustomer } from '../../../../utils/customer'
import { ORDER_TYPE_SERVICE } from '../../../../utils/constants'

const styleSheet = {}

class Address extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired
	}

	render() {
		const { appointment, order } = this.props

		const formattedCustomer = formatCustomer(order.customer)
		const isServiceOrder = order.orderType.key === ORDER_TYPE_SERVICE

		const smsNumber = formattedCustomer.mobileNumber
		const email = formattedCustomer.email
		const addressLine = formattedCustomer.addressLine

		const from = moment.tz(appointment.deliveryDate.from, 'Europe/Amsterdam')
		const till = moment.tz(appointment.deliveryDate.till, 'Europe/Amsterdam')
		const hasDifferentTime = from.hour() !== 0 || till.hour() !== 23

		let line1
		let firstPart
		if (hasDifferentTime) {
			firstPart = (
				<ChatBubbleText paragraph>
					<Trans
						i18n={i18nCallScript}
						i18nKey="callscript:Address.Question.firstPartWithDifferentTime"
					>
						Wij leveren zoals afgesproken{' '}
						<strong>
							tussen {from.format('H:mm')} en {till.format('H:mm')}
						</strong>
						.
					</Trans>
				</ChatBubbleText>
			)
		} else if (isServiceOrder) {
			firstPart = (
				<ChatBubbleText paragraph>
					{i18nCallScript.t(
						'callscript:Address.Question.firstPartServiceOrder'
					)}
				</ChatBubbleText>
			)
		} else {
			firstPart = (
				<ChatBubbleText paragraph>
					{i18nCallScript.t('callscript:Address.Question.firstPart')}
				</ChatBubbleText>
			)
		}
		const lastPart = (
			<ChatBubbleText>
				<Trans
					i18n={i18nCallScript}
					i18nKey="callscript:Address.Question.lastPart"
				>
					De order wordt geleverd op <strong>{addressLine}</strong>.
				</Trans>
			</ChatBubbleText>
		)
		if (smsNumber && email) {
			line1 = (
				<div>
					{firstPart}
					<ChatBubbleText paragraph>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Question.line1WithEmailAndSMS"
						>
							Twee werkdagen voor de levering krijgt u een sms via{' '}
							<strong>{smsNumber}</strong> en een e-mail op{' '}
							<strong>{email}</strong> met het tijdsblok. Op dit e-mailadres
							krijgt u vanavond ook een bevestiging van de leverafspraak.
						</Trans>
					</ChatBubbleText>
					{lastPart}
				</div>
			)
		} else if (smsNumber) {
			line1 = (
				<div>
					{firstPart}
					<ChatBubbleText paragraph>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Question.line1WithSMS"
						>
							Twee werkdagen voor de levering krijgt u een sms via{' '}
							<strong>{smsNumber}</strong> met het tijdsblok.
						</Trans>
					</ChatBubbleText>
					{lastPart}
				</div>
			)
		} else if (email) {
			line1 = (
				<div>
					{firstPart}
					<ChatBubbleText paragraph>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Question.line1WithEmail"
						>
							Twee werkdagen voor de levering krijgt u een e-mail op{' '}
							<strong>{email}</strong> met het tijdsblok. Op dit e-mailadres
							krijgt u vanavond ook een bevestiging van de leverafspraak.
						</Trans>
					</ChatBubbleText>
					{lastPart}
				</div>
			)
		} else {
			line1 = (
				<div>
					{firstPart}
					<ChatBubbleText paragraph>
						{i18nCallScript.t('callscript:Address.Question.line1')}
					</ChatBubbleText>
					{lastPart}
				</div>
			)
		}

		let answer
		if (appointment.customer) {
			let answerLine
			// Check for differences, and if there are any, check if it were additions, changes, or both
			const formattedAppointmentCustomer = formatCustomer(appointment.customer)
			let hasAdditions = false
			let hasChanges = false
			if (formattedCustomer.street !== formattedAppointmentCustomer.street) {
				if (!formattedCustomer.street) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (
				formattedCustomer.houseNumber !==
				formattedAppointmentCustomer.houseNumber
			) {
				if (!formattedCustomer.houseNumber) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (
				formattedCustomer.postCode !== formattedAppointmentCustomer.postCode
			) {
				if (!formattedCustomer.postCode) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (formattedCustomer.city !== formattedAppointmentCustomer.city) {
				if (!formattedCustomer.city) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (formattedCustomer.email !== formattedAppointmentCustomer.email) {
				if (!formattedCustomer.email) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (
				formattedCustomer.telephoneNumber !==
				formattedAppointmentCustomer.telephoneNumber
			) {
				if (!formattedCustomer.telephoneNumber) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (
				formattedCustomer.mobileNumber !==
				formattedAppointmentCustomer.mobileNumber
			) {
				if (!formattedCustomer.mobileNumber) {
					hasAdditions = true
				} else {
					hasChanges = true
				}
			}
			if (hasChanges && hasAdditions) {
				answerLine = (
					<ChatBubbleText>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Answer.line1WithAdditionsAndChanges"
						>
							<strong>Nee</strong>, mijn gegevens moeten aangepast en aangevuld
							worden.
						</Trans>
					</ChatBubbleText>
				)
			} else if (hasChanges) {
				answerLine = (
					<ChatBubbleText>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Answer.line1WithChanges"
						>
							<strong>Nee</strong>, mijn gegevens moeten aangepast worden.
						</Trans>
					</ChatBubbleText>
				)
			} else if (hasAdditions) {
				answerLine = (
					<ChatBubbleText>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Answer.line1WithAdditions"
						>
							<strong>Nee</strong>, mijn gegevens moeten aangevuld worden.
						</Trans>
					</ChatBubbleText>
				)
			} else {
				answerLine = (
					<ChatBubbleText>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Address.Answer.line1"
						>
							<strong>Ja</strong>, mijn gegevens zijn juist.
						</Trans>
					</ChatBubbleText>
				)
			}
			answer = <ChatBubbleAnswer>{answerLine}</ChatBubbleAnswer>
		}

		return (
			<>
				{answer}
				<ChatBubbleQuestion>{line1}</ChatBubbleQuestion>
			</>
		)
	}
}

export default withStyles(styleSheet)(Address)
