import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, SvgIcon, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AngleLeftFal,
	AngleRightFal,
	CalendarAltFal,
	ClockFal
} from '@oliverit/react-fontawesome'
import { DateTimePicker } from '@oliverit/react-material-ui-pickers-moment'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		flex: 1
	},
	picker: {
		flex: 1
	},
	saveButton: {
		marginTop: 16
	}
}

class UnavailabilityItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		create: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		vehicleId: PropTypes.string.isRequired
	}

	state = {
		from: moment.tz('Europe/Amsterdam').set({ hour: 0, minute: 0, seconds: 0 }),
		till: moment
			.tz('Europe/Amsterdam')
			.set({ hour: 23, minute: 59, seconds: 59 })
	}

	handleChangeFrom = (from) => {
		this.setState({
			from
		})
	}

	handleAcceptFrom = (from) => {
		const { till } = this.state

		// 'from' is same or after 'till', shift 'till' to 'from + 15 minutes'
		if (from.isSameOrAfter(till)) {
			this.setState({
				till: moment(from).add(15, 'minutes')
			})
		}
	}

	handleChangeTill = (till) => {
		this.setState({
			till
		})
	}

	handleAcceptTill = (till) => {
		const { from } = this.state

		// 'till' is same or before 'from', shift 'from' to 'till - 15 minutes'
		if (till.isSameOrBefore(from)) {
			this.setState({
				from: moment(till).subtract(15, 'minutes')
			})
		}
	}

	handleSave = () => {
		const { create, history, vehicleId } = this.props
		const { from, till } = this.state

		create(history, {
			vehicleId,
			from: from.unix(),
			till: till.unix()
		})
	}

	render() {
		const { classes } = this.props
		const { from, till } = this.state

		return (
			<div className={classes.root}>
				<Typography variant="h5" className={classes.title}>
					{i18n.t('app:vehicleUnavailability.Item.title')}
				</Typography>
				<DateTimePicker
					value={from}
					onChange={this.handleChangeFrom}
					onAccept={this.handleAcceptFrom}
					ampm={false}
					autoOk={true}
					clearable={false}
					disablePast={true}
					label={i18n.t('app:vehicleUnavailability.Item.from')}
					cancelLabel={i18n.t('app:datepicker.cancel')}
					clearLabel={i18n.t('app:datepicker.clear')}
					format="D MMMM Y, H:mm"
					maxDateMessage={i18n.t(
						'app:vehicleUnavailability.Item.maxDateErrorText'
					)}
					okLabel={i18n.t('app:datepicker.ok')}
					todayLabel={i18n.t('app:datepicker.today')}
					leftArrowIcon={
						<SvgIcon>
							<AngleLeftFal />
						</SvgIcon>
					}
					rightArrowIcon={
						<SvgIcon>
							<AngleRightFal />
						</SvgIcon>
					}
					dateRangeIcon={
						<SvgIcon>
							<CalendarAltFal />
						</SvgIcon>
					}
					timeIcon={
						<SvgIcon>
							<ClockFal />
						</SvgIcon>
					}
					className={classes.picker}
					margin="normal"
				/>
				<DateTimePicker
					value={till}
					onChange={this.handleChangeTill}
					onAccept={this.handleAcceptTill}
					ampm={false}
					autoOk={true}
					clearable={false}
					disablePast={true}
					label={i18n.t('app:vehicleUnavailability.Item.till')}
					cancelLabel={i18n.t('app:datepicker.cancel')}
					clearLabel={i18n.t('app:datepicker.clear')}
					format="D MMMM Y, H:mm"
					maxDateMessage={i18n.t(
						'app:vehicleUnavailability.Item.maxDateErrorText'
					)}
					okLabel={i18n.t('app:datepicker.ok')}
					todayLabel={i18n.t('app:datepicker.today')}
					leftArrowIcon={
						<SvgIcon>
							<AngleLeftFal />
						</SvgIcon>
					}
					rightArrowIcon={
						<SvgIcon>
							<AngleRightFal />
						</SvgIcon>
					}
					dateRangeIcon={
						<SvgIcon>
							<CalendarAltFal />
						</SvgIcon>
					}
					timeIcon={
						<SvgIcon>
							<ClockFal />
						</SvgIcon>
					}
					className={classes.picker}
					margin="normal"
				/>
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleSave}
					className={classes.saveButton}
				>
					{i18n.t('app:vehicleUnavailability.Item.save')}
				</Button>
			</div>
		)
	}
}

export default withRouter(withStyles(styles)(UnavailabilityItem))
