import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import ChatBubbleAnswer from './ChatBubbleAnswer'
import {
	i18nCallScript,
	isExternalElevatorAnswered,
	isExternalElevatorAvailable
} from '../../../../utils/callscript'

const styleSheet = {}

class ExternalElevator extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired
	}

	render() {
		const { appointment, lng } = this.props

		let line1
		switch (lng) {
			default: {
				line1 = (
					<ChatBubbleText>
						{i18nCallScript.t('callscript:ExternalElevator.Question.line1')}
					</ChatBubbleText>
				)
			}
		}

		let answer
		if (isExternalElevatorAnswered(appointment)) {
			let answerLine
			switch (lng) {
				default: {
					if (isExternalElevatorAvailable(appointment)) {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:ExternalElevator.Answer.line1Yes"
								>
									<strong>Ja</strong>, er kan een lift geplaatst worden.
								</Trans>
							</ChatBubbleText>
						)
					} else {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:ExternalElevator.Answer.line1No"
								>
									<strong>Nee</strong>, er kan geen lift geplaatst worden.
								</Trans>
							</ChatBubbleText>
						)
					}
				}
			}
			answer = <ChatBubbleAnswer>{answerLine}</ChatBubbleAnswer>
		}

		return (
			<>
				{answer}
				<ChatBubbleQuestion>{line1}</ChatBubbleQuestion>
			</>
		)
	}
}

export default withStyles(styleSheet)(ExternalElevator)
