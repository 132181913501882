import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	IconButton,
	Menu,
	MenuItem,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { HistoryFal, LanguageFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import ContactHistory from '../../Order/ContactHistory'
import Column from '../Column'
import PhoneNumber from './PhoneNumber'
import DeliveryDate from './DeliveryDate'
import Address from './Address'
import FloorNumber from './FloorNumber'
import Elevator from './Elevator'
import Stairs from './Stairs'
import Scaffold from './Scaffold'
import ExternalElevator from './ExternalElevator'
import Remarks from './Remarks'
import Confirm from './Confirm'
import Abort from './Abort'
import config from '../../../../config'
import { i18nCallScript, languages } from '../../../../utils/callscript'

const styleSheet = {
	headerRow: {
		display: 'flex',
		alignItems: 'center'
	},
	headerText: {
		flex: 1
	}
}

class Script extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		appointment: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		ownFullName: PropTypes.string.isRequired,
		selectedStep: PropTypes.string.isRequired,
		stepFlow: PropTypes.array.isRequired
	}

	state = {
		lng: config.userLanguage,
		languageMenuAnchorEl: null,
		showHistory: false
	}

	handleHideHistory = () => {
		this.setState({
			showHistory: false
		})
	}

	handleShowHistory = () => {
		this.setState({
			showHistory: true
		})
	}

	handleOpenLanguageMenu = (event) => {
		this.setState({
			languageMenuAnchorEl: event.currentTarget
		})
	}

	handleSelectLanguage = (lng) => {
		this.closeLanguageMenu()
		i18nCallScript.changeLanguage(lng, (err) => {
			if (!err) {
				// Update the lng state to make sure the component will render after changing the i18next language
				this.setState({
					lng
				})
			}
		})
	}

	handleCloseLanguageMenu = () => {
		this.closeLanguageMenu()
	}

	closeLanguageMenu() {
		this.setState({
			languageMenuAnchorEl: null
		})
	}

	render() {
		const { appointment, classes, order, ownFullName, stepFlow } = this.props
		const { languageMenuAnchorEl, lng, showHistory } = this.state

		// Call script language
		const languageMenuItems = languages().map((language) => (
			<MenuItem
				key={`language-${language.id}`}
				onClick={() => this.handleSelectLanguage(language.id)}
			>
				{language.description}
			</MenuItem>
		))
		const languageMenu = (
			<Menu
				anchorEl={languageMenuAnchorEl}
				open={Boolean(languageMenuAnchorEl)}
				onClose={this.handleCloseLanguageMenu}
			>
				{languageMenuItems}
			</Menu>
		)
		const languageMenuButton = (
			<IconButton color="primary" onClick={this.handleOpenLanguageMenu}>
				<SvgIcon>
					<LanguageFal />
				</SvgIcon>
			</IconButton>
		)

		// Contact history
		let historyButton
		let historyDialog
		if (order.attachments && order.attachments.length > 0) {
			historyDialog = (
				<Dialog fullWidth open={showHistory} onClose={this.handleHideHistory}>
					<ContactHistory order={order} />
				</Dialog>
			)
			historyButton = (
				<IconButton color="primary" onClick={this.handleShowHistory}>
					<SvgIcon>
						<HistoryFal />
					</SvgIcon>
				</IconButton>
			)
		}

		// Header
		const headerButtons = (
			<div>
				{historyButton}
				{languageMenuButton}
			</div>
		)
		const header = (
			<div className={classes.headerRow}>
				<Typography variant="h5" className={classes.headerText}>
					{i18n.t('app:appointmentscheduler.Order.Edit.CallScript.title')}
				</Typography>
				{headerButtons}
				{languageMenu}
				{historyDialog}
			</div>
		)

		// Chat bubbles
		const chats = []
		const steps = [...stepFlow] // Copy the steps before reversing, because reverse is a mutating function
		steps.reverse().forEach((step) => {
			switch (step) {
				case 'date': {
					chats.push(
						<DeliveryDate
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
							ownFullName={ownFullName}
						/>
					)
					break
				}
				case 'address': {
					chats.push(
						<Address
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'floornumber': {
					chats.push(
						<FloorNumber
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'elevator': {
					chats.push(
						<Elevator
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'stairs': {
					chats.push(
						<Stairs
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'scaffold': {
					chats.push(
						<Scaffold
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'externalelevator': {
					chats.push(
						<ExternalElevator
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'remarks': {
					chats.push(
						<Remarks
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'confirm': {
					chats.push(
						<Confirm
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				case 'abort': {
					chats.push(
						<Abort
							key={`order-${order.id}-step-${step}`}
							lng={lng}
							appointment={appointment}
							order={order}
						/>
					)
					break
				}
				default: {
					// Nothing to do
				}
			}
		})

		const content = (
			<>
				{chats}
				<PhoneNumber order={order} />
			</>
		)

		return <Column header={header}>{content}</Column>
	}
}

export default withStyles(styleSheet)(Script)
