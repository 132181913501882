import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import { Trans } from 'react-i18next'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import ChatBubbleAnswer from './ChatBubbleAnswer'
import { i18nCallScript } from '../../../../utils/callscript'

const styleSheet = {}

class FloorNumber extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired
	}

	render() {
		const { appointment, order, lng } = this.props

		const floorNumber = order.floorNumber ? order.floorNumber : 0

		let line1
		switch (lng) {
			default: {
				let floorNumberText
				if (floorNumber === 0) {
					floorNumberText = i18nCallScript.t(
						'callscript:FloorNumber.Question.firstFloor'
					)
				} else {
					floorNumberText = i18nCallScript.t(
						'callscript:FloorNumber.Question.floor',
						{
							floorNumber: numeral(floorNumber).format('0o')
						}
					)
				}
				line1 = (
					<ChatBubbleText>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:FloorNumber.Question.line1"
						>
							Uw order mag op <strong>{floorNumberText}</strong> geleverd
							worden, klopt dit?
						</Trans>
					</ChatBubbleText>
				)
			}
		}

		let answer
		if (appointment.floorNumber !== null) {
			let answerLine
			const isSame = floorNumber === appointment.floorNumber
			switch (lng) {
				default: {
					let floorNumberText
					if (appointment.floorNumber === 0) {
						floorNumberText = i18nCallScript.t(
							'callscript:FloorNumber.Question.firstFloor'
						)
					} else {
						floorNumberText = i18nCallScript.t(
							'callscript:FloorNumber.Question.floor',
							{
								floorNumber: numeral(appointment.floorNumber).format('0o')
							}
						)
					}
					if (isSame) {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:FloorNumber.Answer.line1Yes"
								>
									<strong>Klopt</strong>, er moet geleverd worden op de{' '}
									{floorNumberText}.
								</Trans>
							</ChatBubbleText>
						)
					} else {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:FloorNumber.Answer.line1No"
								>
									<strong>Nee</strong>, er moet geleverd worden op de{' '}
									{floorNumberText}.
								</Trans>
							</ChatBubbleText>
						)
					}
				}
			}
			answer = <ChatBubbleAnswer>{answerLine}</ChatBubbleAnswer>
		}

		return (
			<>
				{answer}
				<ChatBubbleQuestion>{line1}</ChatBubbleQuestion>
			</>
		)
	}
}

export default withStyles(styleSheet)(FloorNumber)
