import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { i18nCallScript } from '../../../../utils/callscript'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import {
	salesOrganizationEmailAddress,
	salesOrganizationServiceEmailAddress
} from '../../../../utils/salesOrganization'
import { ORDER_TYPE_SERVICE } from '../../../../utils/constants'

const styleSheet = {}

class Abort extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired
	}

	render() {
		const { order } = this.props

		const isServiceOrder = order.orderType.key === ORDER_TYPE_SERVICE
		const email = isServiceOrder
			? salesOrganizationServiceEmailAddress(order.salesOrganization.key)
			: salesOrganizationEmailAddress(order.salesOrganization.key)

		let line1
		if (email) {
			line1 = (
				<ChatBubbleText paragraph>
					<Trans
						i18n={i18nCallScript}
						i18nKey="callscript:Abort.Question.line1WithEmail"
					>
						Zijn er nog andere mogelijkheden om de order te kunnen leveren? Zo
						niet, dan willen wij u vragen foto’s van uw raam te sturen naar{' '}
						<strong>{email}</strong>
					</Trans>
				</ChatBubbleText>
			)
		} else {
			line1 = (
				<ChatBubbleText paragraph>
					{i18nCallScript.t('callscript:Abort.Question.line1')}
				</ChatBubbleText>
			)
		}
		const line2 = (
			<ChatBubbleText>
				{i18nCallScript.t('callscript:Abort.Question.line2')}
			</ChatBubbleText>
		)

		return (
			<ChatBubbleQuestion>
				{line1}
				{line2}
			</ChatBubbleQuestion>
		)
	}
}

export default withStyles(styleSheet)(Abort)
