import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = (theme) => ({
	normal: {},
	contrast: {
		color: theme.palette.primary.contrastText
	}
})

class ChatBubbleText extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired,
		contrast: PropTypes.bool
	}

	render() {
		const { classes, children, contrast, ...rest } = this.props

		if (contrast) {
			return (
				<Typography variant="body2" className={classes.contrast} {...rest}>
					{children}
				</Typography>
			)
		}
		return (
			<Typography variant="body2" className={classes.normal} {...rest}>
				{children}
			</Typography>
		)
	}
}

export default withStyles(styleSheet)(ChatBubbleText)
