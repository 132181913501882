import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { Trans } from 'react-i18next'
import { i18nCallScript } from '../../../../utils/callscript'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import { formatMoney } from '../../../../utils/currency'

const styleSheet = {}

class Confirm extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired
	}

	render() {
		const { appointment, order, lng } = this.props

		const deliveryDate = moment
			.tz(appointment.deliveryDate.from, 'Europe/Amsterdam')
			.format('dddd D MMMM Y')

		let line1
		let line2
		switch (lng) {
			default: {
				line1 = (
					<ChatBubbleText paragraph>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Confirm.Question.line1"
						>
							U kunt het restantbedrag van{' '}
							<strong>{formatMoney(order.openAmount)}</strong> per pin of
							contant betalen bij onze bezorgers. Onze voorkeur gaat uit naar
							een pinbetaling. Let daarbij op het daglimiet van uw bankpas.
						</Trans>
					</ChatBubbleText>
				)
				line2 = (
					<ChatBubbleText>
						<Trans
							i18n={i18nCallScript}
							i18nKey="callscript:Confirm.Question.line2"
						>
							Ik heb u ingepland op <strong>{deliveryDate}</strong>. Heeft u
							verder nog vragen? Dan wens ik u een prettige dag verder.
						</Trans>
					</ChatBubbleText>
				)
			}
		}

		return (
			<ChatBubbleQuestion>
				{line1}
				{line2}
			</ChatBubbleQuestion>
		)
	}
}

export default withStyles(styleSheet)(Confirm)
