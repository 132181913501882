import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { formatCustomer } from '../../../../utils/customer'
import alohaUrl from '../../../../utils/aloha'

const styleSheet = {
	root: {
		marginLeft: 'auto'
	},
	phoneButton: {
		marginLeft: 16
	}
}

class PhoneNumber extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const formattedCustomer = formatCustomer(order.customer)
		const telephoneNumber = formattedCustomer.telephoneNumber
		const mobileNumber = formattedCustomer.mobileNumber

		const telephoneUrl = alohaUrl(
			order.planningType.key,
			order.salesOrganization.key,
			telephoneNumber
		)
		const mobileUrl = alohaUrl(
			order.planningType.key,
			order.salesOrganization.key,
			mobileNumber
		)

		let telephoneNumberButton
		let mobileNumberButton
		if (telephoneNumber) {
			telephoneNumberButton = (
				<Button
					size="large"
					variant="outlined"
					color="primary"
					className={classes.phoneButton}
					href={telephoneUrl}
					target="_blank"
				>
					{telephoneNumber}
				</Button>
			)
		}
		if (mobileNumber) {
			mobileNumberButton = (
				<Button
					size="large"
					variant="outlined"
					color="primary"
					className={classes.phoneButton}
					href={mobileUrl}
					target="_blank"
				>
					{mobileNumber}
				</Button>
			)
		}

		return (
			<div className={classes.root}>
				{telephoneNumberButton}
				{mobileNumberButton}
			</div>
		)
	}
}

export default withStyles(styleSheet)(PhoneNumber)
