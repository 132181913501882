import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	DialogTitle,
	IconButton,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AngleLeftFal,
	AngleRightFal,
	CommentFal,
	CommentFas,
	TrashAltFal
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import Column from '../Column'
import DeliveryDate from './DeliveryDate'
import Address from './Address'
import FloorNumber from './FloorNumber'
import Elevator from './Elevator'
import Stairs from './Stairs'
import Scaffold from './Scaffold'
import ExternalElevator from './ExternalElevator'
import Remarks from './Remarks'
import Note from './Note'
import Confirm from './Confirm'
import Abort from './Abort'
import ConfirmDialog from '../../../Shared/ConfirmDialog'

const styleSheet = {
	headerText: {
		flex: 1
	},
	headerRow: {
		display: 'flex',
		alignItems: 'center'
	}
}

class Form extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		confirm: PropTypes.func.isRequired,
		dateProposal: PropTypes.object,
		dateProposalLoading: PropTypes.bool,
		order: PropTypes.object.isRequired,
		getProposal: PropTypes.func.isRequired,
		navigateToNextStep: PropTypes.func,
		navigateToPreviousStep: PropTypes.func,
		reset: PropTypes.func.isRequired,
		setAddress: PropTypes.func.isRequired,
		setCanElevatorBeUsed: PropTypes.func.isRequired,
		setCanExternalElevatorBeUsed: PropTypes.func.isRequired,
		setCanScaffoldBeUsed: PropTypes.func.isRequired,
		setCanStairsBeUsed: PropTypes.func.isRequired,
		setDeliveryDate: PropTypes.func.isRequired,
		setFloorNumber: PropTypes.func.isRequired,
		setNote: PropTypes.func.isRequired,
		setRemarks: PropTypes.func.isRequired,
		selectedStep: PropTypes.string.isRequired,
		timeSlots: PropTypes.array.isRequired,
		unavailableProposalMomentsForOrder: PropTypes.array.isRequired
	}

	state = {
		formKey: 1, // This field is used to trigger a re-render of the form when the form is reset
		showConfirmResetDialog: false,
		showNote: false
	}

	handleHideNote = () => {
		this.setState({
			showNote: false
		})
	}

	handleShowNote = () => {
		this.setState({
			showNote: true
		})
	}

	handleReset = () => {
		this.setState({
			showConfirmResetDialog: true
		})
	}

	handleResetCancel = () => {
		this.setState({
			showConfirmResetDialog: false
		})
	}

	handleResetConfirm = () => {
		const { reset } = this.props
		const { formKey } = this.state
		this.setState({
			formKey: formKey + 1,
			showConfirmResetDialog: false
		})
		reset()
	}

	render() {
		const {
			appointment,
			classes,
			confirm,
			dateProposal,
			dateProposalLoading,
			order,
			getProposal,
			navigateToNextStep,
			navigateToPreviousStep,
			setAddress,
			setCanElevatorBeUsed,
			setCanExternalElevatorBeUsed,
			setCanScaffoldBeUsed,
			setCanStairsBeUsed,
			setDeliveryDate,
			setFloorNumber,
			setNote,
			setRemarks,
			selectedStep,
			timeSlots,
			unavailableProposalMomentsForOrder
		} = this.props
		const { formKey, showConfirmResetDialog, showNote } = this.state

		let title
		let form
		switch (selectedStep) {
			case 'date': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.title')}
					</Typography>
				)
				form = (
					<DeliveryDate
						key={formKey}
						appointment={appointment}
						dateProposal={dateProposal}
						dateProposalLoading={dateProposalLoading}
						order={order}
						getProposal={getProposal}
						setDeliveryDate={setDeliveryDate}
						timeSlots={timeSlots}
						tourDate={order.tourDate}
						unavailableProposalMomentsForOrder={
							unavailableProposalMomentsForOrder
						}
					/>
				)
				break
			}
			case 'address': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.title'
						)}
					</Typography>
				)
				form = (
					<Address
						key={formKey}
						appointment={appointment}
						order={order}
						setAddress={setAddress}
					/>
				)
				break
			}
			case 'floornumber': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryFloorNumber.title'
						)}
					</Typography>
				)
				form = (
					<FloorNumber
						key={formKey}
						appointment={appointment}
						order={order}
						setFloorNumber={setFloorNumber}
					/>
				)
				break
			}
			case 'elevator': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryElevator.title'
						)}
					</Typography>
				)
				form = (
					<Elevator
						key={formKey}
						appointment={appointment}
						order={order}
						setCanElevatorBeUsed={setCanElevatorBeUsed}
					/>
				)
				break
			}
			case 'stairs': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryStairs.title')}
					</Typography>
				)
				form = (
					<Stairs
						key={formKey}
						appointment={appointment}
						order={order}
						setCanStairsBeUsed={setCanStairsBeUsed}
					/>
				)
				break
			}
			case 'scaffold': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryScaffold.title'
						)}
					</Typography>
				)
				form = (
					<Scaffold
						key={formKey}
						appointment={appointment}
						order={order}
						setCanScaffoldBeUsed={setCanScaffoldBeUsed}
					/>
				)
				break
			}
			case 'externalelevator': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryExternalElevator.title'
						)}
					</Typography>
				)
				form = (
					<ExternalElevator
						key={formKey}
						appointment={appointment}
						order={order}
						setCanExternalElevatorBeUsed={setCanExternalElevatorBeUsed}
					/>
				)
				break
			}
			case 'remarks': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryRemarks.title'
						)}
					</Typography>
				)
				form = (
					<Remarks
						key={formKey}
						appointment={appointment}
						order={order}
						setRemarks={setRemarks}
					/>
				)
				break
			}
			case 'confirm': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t('app:appointmentscheduler.Order.Edit.OrderConfirm.title')}
					</Typography>
				)
				form = (
					<Confirm
						key={formKey}
						appointment={appointment}
						order={order}
						confirm={confirm}
					/>
				)
				break
			}
			case 'abort': {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryAbort.title')}
					</Typography>
				)
				form = <Abort key={formKey} appointment={appointment} order={order} />
				break
			}
			default: {
				title = (
					<Typography variant="h5" className={classes.headerText}>
						{i18n.t('app:appointmentscheduler.Order.Edit.invalidStep')}
					</Typography>
				)
				form = null
			}
		}

		const noteDialog = (
			<Dialog fullWidth open={showNote} onClose={this.handleHideNote}>
				<DialogTitle>
					{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryNote.title')}
				</DialogTitle>
				<Note
					close={this.handleHideNote}
					appointment={appointment}
					order={order}
					setNote={setNote}
				/>
			</Dialog>
		)

		const noteIconButton = appointment.note ? <CommentFas /> : <CommentFal />

		const header = (
			<div className={classes.headerRow}>
				{title}
				<IconButton
					color="primary"
					disabled={!navigateToPreviousStep}
					onClick={navigateToPreviousStep}
				>
					<SvgIcon>
						<AngleLeftFal />
					</SvgIcon>
				</IconButton>
				<IconButton
					color="primary"
					disabled={!navigateToNextStep}
					onClick={navigateToNextStep}
				>
					<SvgIcon>
						<AngleRightFal />
					</SvgIcon>
				</IconButton>
				<IconButton color="primary" onClick={this.handleShowNote}>
					<SvgIcon>{noteIconButton}</SvgIcon>
				</IconButton>
				<IconButton color="secondary" onClick={this.handleReset}>
					<SvgIcon>
						<TrashAltFal />
					</SvgIcon>
				</IconButton>
			</div>
		)

		return (
			<>
				<Column header={header}>{form}</Column>
				{noteDialog}
				<ConfirmDialog
					cancel={this.handleResetCancel}
					cancelButtonText={i18n.t(
						'app:appointmentscheduler.Order.Edit.ConfirmResetDialog.cancel'
					)}
					confirm={this.handleResetConfirm}
					confirmButtonText={i18n.t(
						'app:appointmentscheduler.Order.Edit.ConfirmResetDialog.confirm'
					)}
					descriptionText={i18n.t(
						'app:appointmentscheduler.Order.Edit.ConfirmResetDialog.description'
					)}
					isDestructive
					isOpen={showConfirmResetDialog}
					titleText={i18n.t(
						'app:appointmentscheduler.Order.Edit.ConfirmResetDialog.title'
					)}
				/>
			</>
		)
	}
}

export default withStyles(styleSheet)(Form)
