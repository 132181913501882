import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	IconButton,
	Paper,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TimePicker } from '@oliverit/react-material-ui-pickers-moment'
import { ExclamationFal, TrashAltFal } from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'
import { default as i18n } from 'i18next'

const MIN_AVAILABILITY_INTERVAL = 15 * 60 // 15 minutes, in seconds

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16
		})
	},
	header: {
		display: 'flex',
		alignItems: 'center'
	},
	headerText: {
		flex: 1
	},
	timeSelect: {
		paddingTop: 16
	},
	helperTextWithIcon: {
		display: 'flex',
		alignItems: 'center'
	},
	helperTextIcon: {
		fontSize: 16,
		marginRight: 4
	},
	saveButton: {
		marginTop: 16
	}
})

class AvailabilityItemDetails extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		item: PropTypes.object,
		save: PropTypes.func.isRequired,
		remove: PropTypes.func.isRequired
	}

	state = {
		fromError: false,
		fromHelperText: '',
		tillError: false,
		tillHelperText: '',
		originalFrom: 0,
		originalTill: 0,
		from: 0,
		till: 0
	}

	static getDerivedStateFromProps(props, state) {
		const { item } = props
		const { originalFrom, originalTill } = state

		// if the from and till timestamps are the same, the selected item is the same, no need to set the state
		if (item.from === originalFrom && item.till === originalTill) {
			return null
		}

		// new item
		if (item.from === null && item.till === null) {
			return {
				date: item.date,
				fromError: false,
				fromHelperText: '',
				tillError: false,
				tillHelperText: '',
				originalFrom: item.from,
				originalTill: item.till,
				from: moment
					.unix(item.date)
					.tz('Europe/Amsterdam')
					.set({ hour: 5, minute: 0, seconds: 0 }),
				till: moment
					.unix(item.date)
					.tz('Europe/Amsterdam')
					.set({ hour: 23, minute: 59, seconds: 59 })
			}
		}

		return {
			date: item.date,
			fromError: false,
			fromHelperText: '',
			tillError: false,
			tillHelperText: '',
			originalFrom: item.from,
			originalTill: item.till,
			from: moment.unix(item.from).tz('Europe/Amsterdam'),
			till: moment.unix(item.till).tz('Europe/Amsterdam')
		}
	}

	handleChangeFrom = (from) => {
		const { till } = this.state

		if (moment(till).unix() <= moment(from).unix()) {
			// till cannot be before or the same as from
			this.setState({
				from,
				fromError: true,
				fromHelperText: i18n.t(
					'app:driveradmin.DriverEdit.AvailabilityItem.fromErrorText'
				),
				tillError: false,
				tillHelperText: ''
			})
		} else if (
			moment(till).unix() - moment(from).unix() <=
			MIN_AVAILABILITY_INTERVAL
		) {
			// difference between from and till is less than the minimal interval, show warning
			this.setState({
				from,
				fromError: false,
				fromHelperText: i18n.t(
					'app:driveradmin.DriverEdit.AvailabilityItem.fromWarningText'
				),
				tillError: false,
				tillHelperText: ''
			})
		} else {
			this.setState({
				from,
				fromError: false,
				fromHelperText: '',
				tillError: false,
				tillHelperText: ''
			})
		}
	}

	handleChangeTill = (till) => {
		const { from } = this.state

		if (moment(till).unix() <= moment(from).unix()) {
			// till cannot be before or the same as from
			this.setState({
				till,
				tillError: true,
				tillHelperText: i18n.t(
					'app:driveradmin.DriverEdit.AvailabilityItem.tillErrorText'
				),
				fromError: false,
				fromHelperText: ''
			})
		} else if (
			moment(till).unix() - moment(from).unix() <=
			MIN_AVAILABILITY_INTERVAL
		) {
			// difference between from and till is less than the minimal interval, show warning
			this.setState({
				till,
				tillError: false,
				tillHelperText: i18n.t(
					'app:driveradmin.DriverEdit.AvailabilityItem.tillWarningText'
				),
				fromError: false,
				fromHelperText: ''
			})
		} else {
			this.setState({
				till,
				tillError: false,
				tillHelperText: '',
				fromError: false,
				fromHelperText: ''
			})
		}
	}

	handleDelete = () => {
		const { remove } = this.props
		remove()
	}

	handleSave = () => {
		const { save } = this.props
		const { from, till } = this.state

		save({
			from: from.unix(),
			till: till.unix()
		})
	}

	render() {
		const { classes } = this.props
		const {
			date,
			from,
			fromError,
			fromHelperText,
			originalFrom,
			originalTill,
			till,
			tillError,
			tillHelperText
		} = this.state

		let header
		if (originalFrom === null && originalTill === null) {
			// if the original 'from' and 'till' timestamp are 'null' this is a new item
			header = (
				<div className={classes.header}>
					<Typography variant="h6" className={classes.headerText}>
						{i18n.t('app:driveradmin.DriverEdit.AvailabilityItem.newTitle')}
					</Typography>
				</div>
			)
		} else {
			header = (
				<div className={classes.header}>
					<Typography variant="h6" className={classes.headerText}>
						{i18n.t('app:driveradmin.DriverEdit.AvailabilityItem.title')}
					</Typography>
					<IconButton color="secondary" onClick={this.handleDelete}>
						<SvgIcon>
							<TrashAltFal />
						</SvgIcon>
					</IconButton>
				</div>
			)
		}

		let fromHelperTextItem
		if (fromHelperText && fromError) {
			// a helper text has to be shown and it's an error, just display the error
			fromHelperTextItem = fromHelperText
		} else if (fromHelperText) {
			// a helper text has to be shown but it isn't an error, add a '!'' icon to the helper text
			fromHelperTextItem = (
				<span className={classes.helperTextWithIcon}>
					<SvgIcon className={classes.helperTextIcon}>
						<ExclamationFal />
					</SvgIcon>{' '}
					{fromHelperText}
				</span>
			)
		}

		let tillHelperTextItem
		if (tillHelperText && tillError) {
			// a helper text has to be shown and it's an error, just display the error
			tillHelperTextItem = tillHelperText
		} else if (tillHelperText) {
			// a helper text has to be shown but it isn't an error, add a '!'' icon to the helper text
			tillHelperTextItem = (
				<span className={classes.helperTextWithIcon}>
					<SvgIcon className={classes.helperTextIcon}>
						<ExclamationFal />
					</SvgIcon>{' '}
					{tillHelperText}
				</span>
			)
		}

		return (
			<Paper className={classes.root}>
				{header}
				<Typography variant="caption" color="primary">
					{i18n.t('app:driveradmin.DriverEdit.AvailabilityItem.date')}
				</Typography>
				<Typography variant="body2">
					{moment.unix(date).tz('Europe/Amsterdam').format('D MMMM YYYY')}
				</Typography>
				<div className={classes.timeSelect}>
					<TimePicker
						label={i18n.t('app:driveradmin.DriverEdit.AvailabilityItem.from')}
						value={from}
						mask={[/\d/, /\d/, ':', /\d/, /\d/]}
						ampm={false}
						onChange={this.handleChangeFrom}
						error={fromError}
						helperText={fromHelperTextItem}
					/>
				</div>
				<div className={classes.timeSelect}>
					<TimePicker
						label={i18n.t('app:driveradmin.DriverEdit.AvailabilityItem.till')}
						mask={[/\d/, /\d/, ':', /\d/, /\d/]}
						value={till}
						ampm={false}
						onChange={this.handleChangeTill}
						error={tillError}
						helperText={tillHelperTextItem}
					/>
				</div>
				<Button
					color="primary"
					variant="contained"
					disabled={fromError || tillError}
					onClick={this.handleSave}
					className={classes.saveButton}
				>
					{i18n.t('app:driveradmin.DriverEdit.AvailabilityItem.save')}
				</Button>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(AvailabilityItemDetails)
