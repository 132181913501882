import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { Trans } from 'react-i18next'
import ChatBubbleText from './ChatBubbleText'
import ChatBubbleQuestion from './ChatBubbleQuestion'
import ChatBubbleAnswer from './ChatBubbleAnswer'
import localizedDescription from '../../../../utils/localizedDescription'
import { getSuggestedDate } from '../../../../utils/appointment'
import { i18nCallScript } from '../../../../utils/callscript'
import {
	ORDER_TYPE_SERVICE,
	PRIMARY_MATERIALGROUPS
} from '../../../../utils/constants'

const styleSheet = {
	primaryMaterial: {
		paddingTop: 16,
		paddingLeft: 16,
		paddingBottom: 16
	}
}

class DeliveryDate extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		lng: PropTypes.string.isRequired,
		ownFullName: PropTypes.string.isRequired
	}

	render() {
		const { appointment, classes, order, lng, ownFullName } = this.props

		const isMorning = moment
			.tz('Europe/Amsterdam')
			.isBefore(moment.tz('Europe/Amsterdam').hours(12).minutes(0).seconds(0))

		const salesOrganization = localizedDescription(
			order.salesOrganization.descriptions
		)
		const isServiceOrder = order.orderType.key === ORDER_TYPE_SERVICE

		const suggestedDate = getSuggestedDate(order)
		const suggestedDateString = suggestedDate.format('dddd D MMMM Y')

		let primaryMaterial
		if (!isServiceOrder) {
			primaryMaterial = order.items.find(
				(material) =>
					material.materialGroup &&
					PRIMARY_MATERIALGROUPS.some((PRIMARY_MATERIALGROUP) =>
						material.materialGroup.startsWith(PRIMARY_MATERIALGROUP)
					)
			)
		}

		let line1
		let line2
		switch (lng) {
			default: {
				const greeting = isMorning
					? i18nCallScript.t('callscript:DeliveryDate.Question.greetingMorning')
					: i18nCallScript.t(
							'callscript:DeliveryDate.Question.greetingAfternoon'
					  )
				if (isServiceOrder) {
					line1 = (
						<ChatBubbleText>
							{`${greeting}, `}
							<Trans
								i18n={i18nCallScript}
								i18nKey="callscript:DeliveryDate.Question.line1ServiceOrder"
							>
								<strong>{salesOrganization}</strong>, u spreekt met{' '}
								{ownFullName} van de afdeling planning. Ik bel u voor het maken
								van een afspraak voor een bezoek van onze servicemonteur.
							</Trans>
						</ChatBubbleText>
					)
				} else if (primaryMaterial) {
					line1 = (
						<ChatBubbleText>
							{`${greeting}, `}
							<Trans
								i18n={i18nCallScript}
								i18nKey="callscript:DeliveryDate.Question.line1PrimaryMaterial"
							>
								<strong>{salesOrganization}</strong>, u spreekt met{' '}
								{ownFullName} van de afdeling planning. Ik bel u voor het maken
								van een afspraak voor levering van uw:
							</Trans>
						</ChatBubbleText>
					)
				} else {
					line1 = (
						<ChatBubbleText>
							{`${greeting}, `}
							<Trans
								i18n={i18nCallScript}
								i18nKey="callscript:DeliveryDate.Question.line1"
							>
								<strong>{salesOrganization}</strong>, u spreekt met{' '}
								{ownFullName} van de afdeling planning. Ik bel u voor het maken
								van een afspraak voor levering van uw bestelling.
							</Trans>
						</ChatBubbleText>
					)
				}
				if (suggestedDateString) {
					line2 = (
						<ChatBubbleText>
							<Trans
								i18n={i18nCallScript}
								i18nKey="callscript:DeliveryDate.Question.line2"
							>
								De beste mogelijkheid voor de levering is{' '}
								<strong>{suggestedDateString}</strong>. Komt dat gelegen?
							</Trans>
						</ChatBubbleText>
					)
				}
			}
		}

		let primaryMaterialPart
		if (primaryMaterial) {
			primaryMaterialPart = (
				<div className={classes.primaryMaterial}>
					<ChatBubbleText>
						<strong>
							{localizedDescription(primaryMaterial.material.descriptions)}
						</strong>
					</ChatBubbleText>
					<ChatBubbleText>{`${
						primaryMaterial.quantity.value
					} ${localizedDescription(
						primaryMaterial.quantity.descriptions
					)}`}</ChatBubbleText>
				</div>
			)
		}

		let answer
		if (appointment.deliveryDate) {
			let answerLine
			const from = moment.tz(appointment.deliveryDate.from, 'Europe/Amsterdam')
			const isSame = suggestedDate && suggestedDate.isSame(from, 'day')
			switch (lng) {
				default: {
					if (isSame) {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:DeliveryDate.Answer.line1Yes"
								>
									<strong>Ja</strong>, dit komt gelegen.
								</Trans>
							</ChatBubbleText>
						)
					} else {
						answerLine = (
							<ChatBubbleText>
								<Trans
									i18n={i18nCallScript}
									i18nKey="callscript:DeliveryDate.Answer.line1No"
								>
									<strong>Nee</strong>, dit komt niet gelegen.
								</Trans>
							</ChatBubbleText>
						)
					}
				}
			}
			answer = <ChatBubbleAnswer>{answerLine}</ChatBubbleAnswer>
		}

		return (
			<>
				{answer}
				<ChatBubbleQuestion>
					{line1}
					{primaryMaterialPart}
					{line2}
				</ChatBubbleQuestion>
			</>
		)
	}
}

export default withStyles(styleSheet)(DeliveryDate)
