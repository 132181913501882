import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import CancelButton from './CancelButton'
import Form from './Form'
import Progress from './Progress'
import Script from './Script'
import Summary from './Summary'
import Loading from '../../Shared/Loading'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16,
		paddingTop: 16,
		marginBottom: 16
	},
	headerRow: {
		display: 'flex',
		alignItems: 'center'
	},
	headerText: {
		flex: 1
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	columnLeft: {
		marginRight: 8,
		width: '33%',
		height: '100%'
	},
	columnMiddle: {
		marginLeft: 8,
		marginRight: 8,
		width: '33%',
		height: '100%'
	},
	columnRight: {
		marginLeft: 8,
		width: '34%',
		height: '100%'
	}
}

class OrderEdit extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		confirm: PropTypes.func.isRequired,
		dateProposal: PropTypes.object,
		dateProposalLoading: PropTypes.bool,
		order: PropTypes.object.isRequired,
		getProposal: PropTypes.func.isRequired,
		isUpdating: PropTypes.bool,
		navigateToNextStep: PropTypes.func,
		navigateToPreviousStep: PropTypes.func,
		noAnswer: PropTypes.func.isRequired,
		ownFullName: PropTypes.string.isRequired,
		postpone: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		selectedStep: PropTypes.string.isRequired,
		setAddress: PropTypes.func.isRequired,
		setCanElevatorBeUsed: PropTypes.func.isRequired,
		setCanExternalElevatorBeUsed: PropTypes.func.isRequired,
		setCanScaffoldBeUsed: PropTypes.func.isRequired,
		setCanStairsBeUsed: PropTypes.func.isRequired,
		setDeliveryDate: PropTypes.func.isRequired,
		setFloorNumber: PropTypes.func.isRequired,
		setNote: PropTypes.func.isRequired,
		setRemarks: PropTypes.func.isRequired,
		stepFlow: PropTypes.array.isRequired,
		stop: PropTypes.func.isRequired,
		timeSlots: PropTypes.array.isRequired,
		unavailableProposalMomentsForOrder: PropTypes.array.isRequired
	}

	render() {
		const {
			appointment,
			classes,
			confirm,
			dateProposal,
			dateProposalLoading,
			order,
			getProposal,
			isUpdating,
			navigateToNextStep,
			navigateToPreviousStep,
			noAnswer,
			ownFullName,
			postpone,
			reset,
			selectedStep,
			setAddress,
			setCanElevatorBeUsed,
			setCanExternalElevatorBeUsed,
			setCanScaffoldBeUsed,
			setCanStairsBeUsed,
			setDeliveryDate,
			setFloorNumber,
			setNote,
			setRemarks,
			stepFlow,
			stop,
			timeSlots,
			unavailableProposalMomentsForOrder
		} = this.props

		if (isUpdating) {
			return (
				<Loading message={i18n.t('app:appointmentscheduler.Order.updating')} />
			)
		}

		const salesOrderText =
			order.salesOrderDeliveryGroup && order.salesOrderDeliveryGroup !== '0'
				? `${order.salesOrderNumber} (${order.salesOrderDeliveryGroup})`
				: `${order.salesOrderNumber}`

		const deliveryText = order.deliveryNumber
			? `${order.deliveryNumber}`
			: `${i18n.t('app:appointmentscheduler.Order.deliveryNumberUnknown')}`

		return (
			<div className={classes.root}>
				<Paper className={classes.header}>
					<div className={classes.headerRow}>
						<Typography variant="h4" className={classes.headerText}>
							{i18n.t('app:appointmentscheduler.Order.order')} {salesOrderText}
						</Typography>
						<CancelButton
							canOnlyStopWithoutSaving={order.isAppointmentConfirmed}
							noAnswer={noAnswer}
							postpone={postpone}
							stop={stop}
						/>
					</div>
					<Typography
						variant="h6"
						color="textSecondary"
						className={classes.headerText}
					>
						{i18n.t('app:appointmentscheduler.Order.delivery')} {deliveryText}
					</Typography>
					<Progress selectedStep={selectedStep} />
				</Paper>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<div className={classes.columnLeft}>
							<Script
								appointment={appointment}
								order={order}
								ownFullName={ownFullName}
								selectedStep={selectedStep}
								stepFlow={stepFlow}
							/>
						</div>
						<div className={classes.columnMiddle}>
							<Form
								appointment={appointment}
								confirm={confirm}
								dateProposal={dateProposal}
								dateProposalLoading={dateProposalLoading}
								order={order}
								getProposal={getProposal}
								navigateToNextStep={navigateToNextStep}
								navigateToPreviousStep={navigateToPreviousStep}
								reset={reset}
								setAddress={setAddress}
								setCanElevatorBeUsed={setCanElevatorBeUsed}
								setCanExternalElevatorBeUsed={setCanExternalElevatorBeUsed}
								setCanScaffoldBeUsed={setCanScaffoldBeUsed}
								setCanStairsBeUsed={setCanStairsBeUsed}
								setDeliveryDate={setDeliveryDate}
								setFloorNumber={setFloorNumber}
								setNote={setNote}
								setRemarks={setRemarks}
								selectedStep={selectedStep}
								timeSlots={timeSlots}
								unavailableProposalMomentsForOrder={
									unavailableProposalMomentsForOrder
								}
							/>
						</div>
						<div className={classes.columnRight}>
							<Summary
								appointment={appointment}
								order={order}
								selectedStep={selectedStep}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(OrderEdit)
