import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
	Typography,
	SvgIcon,
	Button,
	Switch,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AngleLeftFal,
	AngleRightFal,
	CalendarAltFal,
	ClockFal
} from '@oliverit/react-fontawesome'
import { DateTimePicker } from '@oliverit/react-material-ui-pickers-moment'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

import Postcode from './Postcode'
import Range from './Range'

import {
	validateCode,
	validateRange,
	validatePartialCode,
	validatePartialRange,
	formatPostcode
} from '../../../utils/postcodes'

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		flex: 1
	},
	rangeSwitch: {
		flex: 1
	},
	picker: {
		flex: 1
	},
	saveButton: {
		marginTop: 16
	}
}

class PostcodeAvailability extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		country: PropTypes.string.isRequired,
		create: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired
	}

	state = {
		start: '',
		end: '',
		from: moment.tz('Europe/Amsterdam').set({ hour: 0, minute: 0, seconds: 0 }),
		till: moment
			.tz('Europe/Amsterdam')
			.set({ hour: 23, minute: 59, seconds: 59 }),
		range: false
	}

	handleChangeRange = (start, end) => {
		const { country } = this.props

		this.setState({
			start,
			end,
			startError: !validatePartialRange(country, start),
			endError: !validatePartialRange(country, end)
		})
	}

	handleChangePostcode = (code) => {
		const { country } = this.props

		this.setState({
			start: code,
			end: '',
			startError: !validatePartialCode(country, code),
			endError: false
		})
	}

	handleToggleRange = () => {
		const { range } = this.state

		this.setState({
			range: !range
		})
	}

	handleChangeFrom = (from) => {
		this.setState({
			from
		})
	}

	handleAcceptFrom = (from) => {
		const { till } = this.state

		// 'from' is same or after 'till', shift 'till' to 'from + 15 minutes'
		if (from.isSameOrAfter(till)) {
			this.setState({
				till: moment(from).add(15, 'minutes')
			})
		}
	}

	handleChangeTill = (till) => {
		this.setState({
			till
		})
	}

	handleAcceptTill = (till) => {
		const { from } = this.state

		// 'till' is same or before 'from', shift 'from' to 'till - 15 minutes'
		if (till.isSameOrBefore(from)) {
			this.setState({
				from: moment(till).subtract(15, 'minutes')
			})
		}
	}

	isValidPostcode = (postcode) => {
		const { country } = this.props
		const { range } = this.state

		if (range) {
			return validateRange(country, postcode)
		}

		return validateCode(country, postcode)
	}

	isInputValid = () => {
		const { start, end, range } = this.state

		const startValid = this.isValidPostcode(start)
		// postcode end is always valid when not entering a range
		const endValid = !range || this.isValidPostcode(end)

		this.setState({
			startError: !startValid,
			endError: !endValid
		})

		return startValid && endValid
	}

	formatPostcode = (postcode) => {
		const { country } = this.props
		const { range } = this.state

		if (!postcode) {
			return postcode
		}

		// ranges do not need formatting, only ranges with digits are supported
		if (range) {
			return postcode
		}

		return formatPostcode(country, postcode)
	}

	handleSave = () => {
		const { country, create, history } = this.props
		const { start, end, from, till } = this.state

		if (this.isInputValid()) {
			const formattedStart = this.formatPostcode(start)
			const formattedEnd = this.formatPostcode(end)

			create(history, {
				country,
				start: formattedStart,
				end: formattedEnd,
				from: from.unix(),
				till: till.unix()
			})
		}
	}

	render() {
		const { classes, country } = this.props
		const { start, end, startError, endError, from, till, range } = this.state

		let saveDisabled = true
		if (range) {
			saveDisabled = !start || startError || !end || endError
		} else {
			saveDisabled = !start || startError
		}

		let postcodeInput
		if (range) {
			postcodeInput = (
				<Range
					country={country}
					startError={startError}
					endError={endError}
					change={this.handleChangeRange}
				/>
			)
		} else {
			postcodeInput = (
				<Postcode
					country={country}
					error={startError}
					change={this.handleChangePostcode}
				/>
			)
		}

		return (
			<div className={classes.root}>
				<Typography variant="h5" className={classes.title}>
					{i18n.t('app:postcodeadmin.Postcode.title')}
				</Typography>
				{postcodeInput}
				<List disablePadding>
					<ListItem button disableGutters onClick={this.handleToggleRange}>
						<ListItemText
							primaryTypographyProps={{ variant: 'body2' }}
							primary={i18n.t('app:postcodeadmin.Postcode.range')}
						/>
						<ListItemSecondaryAction>
							<Switch
								checked={range}
								color="primary"
								onChange={this.handleToggleRange}
								className={classes.rangeSwitch}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
				<DateTimePicker
					value={from}
					onChange={this.handleChangeFrom}
					onAccept={this.handleAcceptFrom}
					ampm={false}
					autoOk={true}
					clearable={false}
					disablePast={true}
					label={i18n.t('app:postcodeadmin.Postcode.from')}
					cancelLabel={i18n.t('app:datepicker.cancel')}
					clearLabel={i18n.t('app:datepicker.clear')}
					format="D MMMM Y, H:mm"
					okLabel={i18n.t('app:datepicker.ok')}
					todayLabel={i18n.t('app:datepicker.today')}
					leftArrowIcon={
						<SvgIcon>
							<AngleLeftFal />
						</SvgIcon>
					}
					rightArrowIcon={
						<SvgIcon>
							<AngleRightFal />
						</SvgIcon>
					}
					dateRangeIcon={
						<SvgIcon>
							<CalendarAltFal />
						</SvgIcon>
					}
					timeIcon={
						<SvgIcon>
							<ClockFal />
						</SvgIcon>
					}
					className={classes.picker}
					margin="normal"
				/>
				<DateTimePicker
					value={till}
					onChange={this.handleChangeTill}
					onAccept={this.handleAcceptTill}
					ampm={false}
					autoOk={true}
					clearable={false}
					disablePast={true}
					label={i18n.t('app:postcodeadmin.Postcode.till')}
					cancelLabel={i18n.t('app:datepicker.cancel')}
					clearLabel={i18n.t('app:datepicker.clear')}
					format="D MMMM Y, H:mm"
					okLabel={i18n.t('app:datepicker.ok')}
					todayLabel={i18n.t('app:datepicker.today')}
					leftArrowIcon={
						<SvgIcon>
							<AngleLeftFal />
						</SvgIcon>
					}
					rightArrowIcon={
						<SvgIcon>
							<AngleRightFal />
						</SvgIcon>
					}
					dateRangeIcon={
						<SvgIcon>
							<CalendarAltFal />
						</SvgIcon>
					}
					timeIcon={
						<SvgIcon>
							<ClockFal />
						</SvgIcon>
					}
					className={classes.picker}
					margin="normal"
				/>
				<Button
					color="primary"
					variant="contained"
					disabled={saveDisabled}
					onClick={this.handleSave}
					className={classes.saveButton}
				>
					{i18n.t('app:postcodeadmin.Postcode.save')}
				</Button>
			</div>
		)
	}
}

export default withRouter(withStyles(styles)(PostcodeAvailability))
