import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { lightGreen } from '@material-ui/core/colors'

const styleSheet = {
	root: {
		backgroundColor: lightGreen[200],
		borderRadius: 12,
		marginBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 16,
		position: 'relative',
		maxWidth: '75%',
		marginLeft: 'auto',
		'&:before': {
			position: 'absolute',
			top: 16,
			right: -8,
			content: "' '",
			display: 'inline-block',
			width: 0,
			height: 0,
			marginRight: 2,
			verticalAlign: 'middle',
			borderTop: '6px solid transparent',
			borderBottom: '6px solid transparent',
			borderLeft: `6px solid ${lightGreen[200]}`
		}
	}
}

class ChatBubbleQuestion extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired
	}

	render() {
		const { classes, children } = this.props

		return <div className={classes.root}>{children}</div>
	}
}

export default withStyles(styleSheet)(ChatBubbleQuestion)
